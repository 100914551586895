import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/node_modules/bootstrap/dist/css/bootstrap.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/node_modules/swiper/swiper-bundle.css");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/node_modules/swiper/modules/navigation.css");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/node_modules/swiper/modules/pagination.css");
